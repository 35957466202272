@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Black.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('./assets/fonts/Inter-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('./assets/fonts/Inter-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('./assets/fonts/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./assets/fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('./assets/fonts/Inter-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('./assets/fonts/Inter-ExtraLight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, Montserrat, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color-basic-300 */
  background-color: #edf1f7;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

div#root {
  display: flex;
  height: 100%;
  max-width: 1440px;
  min-width: 1020px;
  margin: auto;
}

@media (max-width: 758px) {
  div#root {
    max-width: 758px;
    min-width: auto;
  }
}

div#root div#public {
  min-width: 350px !important;
}

div#root > div {
  height: 100%;
  min-height: 100%;
}

a:-webkit-any-link {
  text-decoration: none;
}

.parsed-html {
  padding-left: 24px;
  padding-right: 24px;
  font-size: 15px;
  line-height: 1.7;
  word-wrap: break-word;
  height: 100%;
}
.parsed-html p {
  font-weight: 400;
}
.parsed-html li {
  font-size: 15px;
}

.search-input {
  color: rgb(34, 43, 69);
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: normal;
  background-color: transparent;
  border-width: 0px 0px 1.1px;
  border-color: rgba(0, 0, 0, 0);
  min-height: 32px;
  margin: 3px 8px;
}

.search-input:focus {
  outline: none;
}

.search-input::placeholder {
  color: #95a1b6;
}

.mention-input__input:focus {
  outline: none;
}

.mention-message__suggestions {
  position: absolute;
}

.video-react-controls-enabled {
  padding-top: 50% !important;
}

[data-testid='modal-photo'] {
  left: 0 !important;
}

/*-----------------react-calendar---------------------------*/
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: -apple-system, Montserrat, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(60, 60, 67, 0.3);
  font-size: 13px;
  line-height: 18px;
}
.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #71b8cb;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #71b8cb;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 15px;
  padding-top: 10px;
  background: none;
  text-align: center;
  line-height: 24px;
  font-size: 20px;
  color: #71b8cb;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 50%;
}
.react-calendar__tile--now {
  background: none;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: none;
}
.react-calendar__tile--hasActive {
  background: #71b8cb;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #71b8cb;
}
.react-calendar__tile--active {
  background: #71b8cb;
  color: white;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #71b8cb;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
  border-radius: 50%;
}

a {
  text-decoration: none;
}

/*----------------------React carousel---------------------------*/

.carousel-root {
  width: 100% !important;
}
